export default {
  code: "carWalkTypeChange",
  name: "代步车型修改",
  desc: "代步车型修改",
  func: function() {
    if (this.val === "A") {
      this.pageLayoutMap.coverage.carWalkTypeDesc.show = true;
      this.pageLayoutMap.coverage.carWalkTypeDesc.text = "大众宝来、大众速腾、本田凌派等同等级车型";
    } else if (this.val === "B") {
      this.pageLayoutMap.coverage.carWalkTypeDesc.show = true;
      this.pageLayoutMap.coverage.carWalkTypeDesc.text = "大众迈腾、丰田亚洲龙、大众帕萨特、日产天籁等同等级车型";
    } else if (this.val === "C") {
      this.pageLayoutMap.coverage.carWalkTypeDesc.show = true;
      this.pageLayoutMap.coverage.carWalkTypeDesc.text = "奥迪A6、奔驰C级、宝马3系等同等级车型";
    } else {
      this.pageLayoutMap.coverage.carWalkTypeDesc.text = null;
      this.pageLayoutMap.coverage.carWalkTypeDesc.show = false;
    }
  }
};
