import { keys, cloneDeep } from "lodash";
export default {
  code: "pageToService",
  name: "页面数据转换服务数据",
  desc: "页面数据转换服务数据",
  func: function() {
    const resFormData = {
      terminal: "3"
    };
    const cloneform = cloneDeep(this.pageForm);
    resFormData.id = cloneform.id;
    resFormData.vehicle = cloneform.vehicle; // 车辆信息
    resFormData.customer = cloneform.customer; // 客户信息
    resFormData.coverage = cloneform.coverage; // 车服务信
    resFormData.saleInfo = cloneform.saleInfo; // 销售信息
    resFormData.orderExt = cloneform.orderExt; // 其它信息
    // 图片转换
    resFormData.images = [];
    cloneform.images &&
      keys(cloneform.images).forEach(imageKey => {
        cloneform.images[imageKey] &&
          cloneform.images[imageKey].length &&
          cloneform.images[imageKey].forEach(image => {
            resFormData.images.push(image);
          });
      });
    return resFormData;
  }
};
