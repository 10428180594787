import { getDictInfo } from "@/api/common/dict";
import { request } from "@/api/service";
import { omit } from "lodash";
export default {
  label: "设置保障范围下拉选项",
  code: "setCoverageScopeOptions",
  func: async function(ctx) {
    const targetAttr = "coverageScope";
    try {
      let options = [];
      const res = await request({
        url: "/afis-carservice/prd/feeCfg/dict/factor/item",
        method: "post",
        data: {
          // Mock 获取定价要素
          ...omit(this.pageForm.coverage, ["coverageScope", "productName"]),
          targetAttr
        }
      });
      const dicts = await getDictInfo("CS_COVERAGE_SCOPE");
      const vs = (res || []).map(item => item.value);
      options = dicts.filter(item => vs.includes(item.value));
      // 设置下拉选项
      this.pageLayoutMap.coverage.coverageScope.options = options;
      const values = options.map(item => item.value);
      if (!values.includes(this.pageForm.coverage[targetAttr])) {
        // coverages[targetAttr] = values.length === 1 ? values[0] : null;
        // coverages[targetAttr] &&
        //   this.coverageMap[productCode].priceFactors
        //     .filter((item) => item.fieldCode !== targetAttr)
        //     .map((item) => {
        //       this.queryPriceFactorDicSet(
        //         productCode,
        //         item.fieldCode,
        //         item.dictCode,
        //         ctx,
        //         coverages
        //       );
        //     });
      }
    } catch (e) {
      console.log(e);
    }
  }
};
