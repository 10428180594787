export default {
  code: "setOcrBusilicense",
  name: "识别的营业执照赋值",
  desc: "识别的营业执照赋值",
  func: function(busilicense) {
    if (!(this.pageForm && this.pageForm.customer)) {
      return false;
    }
    const form = this.pageForm.customer;
    const { companyName, socialCreditCode, address, legalName } = busilicense;
    form.name = companyName;
    form.customerName = companyName;
    form.certificateNo = socialCreditCode;
    form.homeAddress = address;
    form.legalName = legalName;
  }
};
