import dayjs from "dayjs";
import { get } from "lodash";
export default {
  code: "dateOpt",
  name: "日期操作",
  desc: "设置日期增加和减少",
  func: function (path, opts) {
    const config = get(this.pageLayoutMap, path, null);
    if (!config) {
      return false;
    }
    if (!this.val) {
      return false;
    }
    let dayOpt = dayjs(this.val);
    opts.forEach(item => {
      dayOpt = dayOpt[item.opt](item.value, item.unit);
    });
    // config.val = dayOpt.valueOf();
    const biEndDate = this.pageForm.vehicle.biEndDate;
    if (!biEndDate || this.pageCache.initFlag) {
      this.setVal(path, dayOpt.valueOf());
      return;
    }
    this.pageCache.initFlag = true;
  }
};
