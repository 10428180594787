import { assign } from "lodash";
import { request } from "@/api/service";
export default {
  code: "getContract",
  name: "合同查询",
  desc: "合同查询",
  func: async function(id) {
    if (this.pageForm.saleInfo && this.pageContext.info) {
      this.pageForm.saleInfo.saleCode = this.pageContext.info.userId;
      this.pageForm.saleInfo.saleName = this.pageContext.info.alias;
      this.pageForm.saleInfo.saleDealerId = this.pageContext.info.orgId;
      this.pageForm.saleInfo.saleDealerName = this.pageContext.info.orgName;
    }
    if (!id) {
      return null;
    }
    const res = await request({
      url: `/afis-carservice/contract/info/${id}`,
      method: "get"
    });
    const { customer, vehicle, coverages, images, orderExt, saleInfo } = res;
    if (id) {
      this.pageForm.id = id;
    }
    if (customer) {
      assign(this.pageForm.customer, customer);
      if (customer.provinceName) {
        this.pageForm.customer.areaCode = [customer.provinceCode, customer.cityCode, customer.countyCode];
      }
    }
    if (vehicle) {
      assign(this.pageForm.vehicle, vehicle);
    }
    // 初始化服务信息列表
    if (coverages) {
      assign(this.pageForm.coverage, coverages[0]);
    }
    if (images && images.length) {
      const imageObj = {};
      images.forEach(image => {
        image.isImage = true;
        image.url = image.filePath;
        if (imageObj[image.fieldCode]) {
          imageObj[image.fieldCode].push(image);
        } else {
          imageObj[image.fieldCode] = [image];
        }
      });
      this.pageForm.images = imageObj;
    }
    if (orderExt) {
      assign(this.pageForm.orderExt, orderExt);
    }
    if (saleInfo) {
      assign(this.pageForm.saleInfo, saleInfo);
    }
  }
};
