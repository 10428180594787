export default {
  code: "setStartDate",
  name: "通过商业险起止期设置合同有效期校验",
  desc: "通过商业险起止期设置合同有效期校验",
  func: function () {
    const form = this.pageForm;
    const { biStartDate, biEndDate } = form.vehicle;
    const nowDate = Date.now();
    if (biStartDate > nowDate) {
      // 判断止期与起期相减是否小于7天，小于则合同生效日期”可选范围商业险起期~商业险止期
      if (biEndDate && biEndDate - biStartDate < 604800000) {
        this.dateMinCustom('coverage.startDate', [], biStartDate);
        this.dateMaxCustom('coverage.startDate', [{ opt: 'add', value: 7, unit: 'day' }], biEndDate);
      } else {
        this.dateMinCustom('coverage.startDate', [], biStartDate);
        this.dateMaxCustom('coverage.startDate', [{ opt: 'add', value: 7, unit: 'day' }], nowDate);
      }
    } else {
      // 否则，合同生效日期”可选范围为T+1~T+7
      // 判断止期与起期相减是否小于7天，小于则合同生效日期”可选范围T+1~商业险止期
      if (biEndDate && biEndDate - biStartDate < 604800000) {
        this.dateMinCustom('coverage.startDate', [{ opt: 'add', value: 1, unit: 'day' }], nowDate);
        this.dateMaxCustom('coverage.startDate', [], biEndDate);
      } else {
        this.dateMinCustom('coverage.startDate', [{ opt: 'add', value: 1, unit: 'day' }], nowDate);
        this.dateMaxCustom('coverage.startDate', [{ opt: 'add', value: 7, unit: 'day' }], nowDate);
      }
    }
    // this.context.pageContext.validateField('coverage', ['startDate']);
  }
};
