const requireJs = require.context("./script/common/", false, /\.js$/);
const requireOrderJs = require.context("./script/order/common/", false, /\.js$/);
const requireProductJs = require.context("./script/order/", true, /\.js$/);
export default function() {
  // eslint-disable-next-line no-new-func
  const ScriptObj = new Function();
  requireJs.keys().forEach(key => {
    const jsConfig = requireJs(key).default;
    ScriptObj.prototype[jsConfig.code] = jsConfig.func;
  });
  requireOrderJs.keys().forEach(key => {
    const jsConfig = requireOrderJs(key).default;
    ScriptObj.prototype[jsConfig.code] = jsConfig.func;
  });
  const { productCode } = this.$route.query;
  requireProductJs.keys().forEach(key => {
    if (key.indexOf(productCode) < 0) {
      return false;
    }
    const jsConfig = requireProductJs(key).default;
    ScriptObj.prototype[jsConfig.code] = jsConfig.func;
  });
  return ScriptObj;
}
