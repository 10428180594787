export default {
  code: "setOcrVehicle",
  name: "识别的行驶证赋值",
  desc: "识别的行驶证赋值",
  func: function(vehicle) {
    if (!(this.pageForm && this.pageForm.vehicle)) {
      return false;
    }
    const form = this.pageForm.vehicle;
    const { brandModel, engineNo, frameNo, plateNo, registDate, useProperty, issueDate } = vehicle;
    // form.brandModel = brandModel;
    // form.modelDesc = brandModel;
    form.brandAndModel = brandModel;
    form.engineNo = engineNo;
    form.frameNo = frameNo;
    form.plateNo = plateNo;
    form.plateFlag = plateNo ? false : true;
    form.registerDate = new Date(registDate).getTime();
    // form.vehicleUsage = useProperty === "营运" ? "1" : "2";
    form.useNature = useProperty === "营运" ? "1" : "2";
    form.issueCardDate = new Date(issueDate).getTime();
  }
};
