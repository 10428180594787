export default {
  code: "customerTypeChange",
  name: "客户类型修改",
  desc: "客户类型修改",
  func: function() {
    if (!this.val) {
      return false;
    }
    if (this.val === "1") {
      // 个人
      this.onShow(["images.idcardFront"]);
      this.offShow(["images.busiLicense"]);
    } else if (this.val === "2") {
      // 公司
      this.onShow(["images.busiLicense"]);
      this.offShow(["images.idcardFront"]);
    }
  }
};
