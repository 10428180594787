import { request } from "@/api/service";
export default {
  label: "通过品牌编码设置档次",
  code: "setCarLevelByBrandCode",
  func: function() {
    request({
      url: "/afis-product/cmc/carBrandSeriesModel/load",
      method: "post",
      data: {
        brandCode: this.form.brandCode
      }
    }).then(res => {
      this.form.carLevel = res.carLevel;
    });
  }
};
