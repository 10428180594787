export default {
  code: "setUploadVal",
  name: "识别并上传图片赋值",
  desc: "识别并上传图片赋值",
  func: function(uploadImg) {
    if (!(this.pageForm && this.pageForm.images)) {
      return false;
    }
    const form = this.pageForm.images;
    if (!form[uploadImg.fieldCode]) {
      form[uploadImg.fieldCode] = [];
    }
    form[uploadImg.fieldCode].push(uploadImg);
  }
};
