import { getDictInfo } from "@/api/common/dict";
import { request } from "@/api/service";
import { omit } from "lodash";
export default {
  label: "设置保障范围下拉选项",
  code: "setCoveragePeriodOptions",
  func: async function(ctx) {
    const targetAttr = "coveragePeriod";
    try {
      let options = [];
      const res = await request({
        url: "/afis-carservice/prd/feeCfg/dict/factor/item",
        method: "post",
        data: {
          // Mock 获取定价要素
          ...omit(this.pageForm.coverage, ["coveragePeriod", "productName"]),
          targetAttr
        }
      });
      const dicts = await getDictInfo("CS_COVERAGE_PERIOD");
      const vs = (res || []).map(item => item.value);
      options = dicts.filter(item => vs.includes(item.value));
      // 设置下拉选项
      this.pageLayoutMap.coverage.coveragePeriod.options = options;
      const values = options.map(item => item.value);
      if (values.length === 1) {
        this.pageForm.coverage[targetAttr] = values[0];
      }
    } catch (e) {
      console.log(e);
    }
  }
};
