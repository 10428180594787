export default {
  h5TemplateConfig: [
    {
      id: "11cdf0db-7b7e-4696-ad54-8030ad80946f",
      componentId: "61b0713461cda9549152fcf6",
      fmCode: "customer",
      fmName: "客户信息",
      labelWidth: 130,
      formItems: [
        {
          component: "HSelect",
          prop: "customerType",
          label: "客户类型",
          show: true,
          disabled: false,
          view: false,
          col: 12,
          rules: [
            {
              required: true,
              disabled: false,
              message: "必填项",
              trigger: "onBlur"
            }
          ],
          dictCode: "CUSTOMER_TYPE",
          id: "1e292b94-c4c8-4a3e-8af3-029f12b6973c"
        },
        {
          component: "HInput",
          prop: "name",
          label: "姓名",
          show: true,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          watch: "if(val==='1'){this.offRequired(['customer.customerType'])}else{this.onRequired(['customer.customerType'])}",
          id: "29416fc1-6937-4d06-8b2f-2a364bc00b62"
        },
        {
          component: "HInputNumber",
          prop: "age",
          label: "年龄",
          show: true,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "16e2c443-ccd1-4dca-b178-207879123484"
        },
        {
          component: "HDatePicker",
          prop: "birthday",
          label: "出生日期",
          show: true,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "e9e58665-0c30-4cc8-ad97-95abfe92236f"
        }
      ]
    },
    {
      id: "71a54499-5305-4af2-b41b-972fe3e0df67",
      componentId: "61b2c4b261cda9549152fcf8",
      fmCode: "vehicle",
      fmName: "车辆信息",
      labelWidth: 130,
      formItems: [
        {
          id: "65086fbe-e52f-4834-9920-7648f3c097ce",
          show: true,
          disabled: false,
          view: false,
          component: "HCascaderApi",
          label: "多级联动",
          prop: "HCascaderApi",
          col: 12,
          rules: [],
          val: [],
          options: [],
          relName: "brandName",
          api: [
            {
              method: "GET",
              url: "/afis-product/cmc/carBrand/dictCode/listAll",
              label: "label",
              value: "value",
              relateFields: []
            }
          ]
        },
        {
          id: "65086fbe-e52f-4834-9920-7648f3c097ce",
          show: true,
          disabled: false,
          view: false,
          component: "HSelect",
          label: "品牌",
          prop: "brandCode",
          col: 12,
          rules: [],
          val: "A0010",
          options: [],
          relName: "brandName",
          api: {
            method: "GET",
            url: "/afis-product/cmc/carBrand/dictCode/listAll",
            label: "label",
            value: "value",
            relateFields: []
          },
          watch: "this.remoteOption('vehicle.seriesCode')"
        },
        {
          component: "HInput",
          prop: "brandName",
          label: "品牌名称",
          show: true,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          val: null,
          id: "d121375e-a346-48c8-9c4a-61d97c1e2f5e"
        },
        {
          id: "cd7d34c4-2893-43db-910b-46f3f13b41bc",
          show: true,
          disabled: false,
          view: false,
          component: "HSelectApi",
          label: "车系",
          prop: "seriesCode",
          col: 12,
          rules: [],
          val: "AS0010200",
          options: [],
          relName: "seriesName",
          api: {
            method: "GET",
            url: "/afis-product/cmc/carSeries/dictCode/listAll/{{brandCode}}",
            label: "label",
            value: "value",
            relateFields: [
              {
                type: "path", // static path
                required: true,
                code: "brandCode",
                value: "vehicle.brandCode"
              }
            ]
          },
          watch: "this.remoteOption('vehicle.modelCode')"
        },
        {
          id: "8d13fed8-2f45-495a-a3a1-1569e3eeae3f",
          show: true,
          disabled: false,
          view: false,
          component: "HInput",
          label: "车系名称",
          prop: "seriesName",
          col: 12,
          rules: [],
          val: null
        },
        {
          id: "69eecb1d-8946-49f1-bb60-f0133cde2703",
          show: true,
          disabled: false,
          view: false,
          component: "HSelectApi",
          label: "车型",
          prop: "modelCode",
          col: 12,
          rules: [],
          val: null,
          options: [],
          relName: "modelName",
          api: {
            method: "GET",
            url: "/afis-product/cmc/carModel/dictCode/listAll/{{brandCode}}/{{seriesCode}}",
            label: "label",
            value: "value",
            relateFields: [
              {
                type: "path", // static path
                required: true,
                code: "brandCode",
                value: "vehicle.brandCode"
              },
              {
                type: "path", // static path
                required: true,
                code: "seriesCode",
                value: "vehicle.seriesCode"
              }
            ]
          }
        },
        {
          id: "586485fb-9f7c-4c4d-95c5-bc162a31dab5",
          show: true,
          disabled: false,
          view: false,
          component: "HInput",
          label: "车型名称",
          prop: "modelName",
          col: 12,
          rules: [],
          val: null
        },
        {
          id: "5be15cad-acd5-4541-ac4d-b7fc40e10465",
          show: true,
          disabled: false,
          view: false,
          component: "HDatePicker",
          label: "商业险起期",
          prop: "biStartDate",
          maxDate: new Date(),
          col: 12,
          watch: "this.dateMin('vehicle.biEndDate');this.dateOpt('vehicle.biEndDate',[{opt:'add',value:1,unit:'year'},{opt:'subtract',value:1,unit:'day'}])",
          rules: []
        },
        {
          id: "1d44dc7b-53f7-4062-bbea-df3ef86278ce",
          show: true,
          disabled: false,
          view: false,
          component: "HDatePicker",
          label: "商业险止期",
          prop: "biEndDate",
          minDate: new Date(),
          col: 12,
          rules: [],
          val: null,
          watch: "this.dateMax('vehicle.biStartDate')"
        },

        {
          component: "HInput",
          prop: "provinceCode",
          label: "省份编码",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "d42ba9cf-8764-4f68-8d5c-3cae488381e3",
          val: null
        },
        {
          component: "HInput",
          prop: "provinceName",
          label: "省份名称",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "0e9357ed-2264-4c1b-811a-4474faecabf2",
          val: null
        },
        {
          component: "HInput",
          prop: "cityCode",
          label: "城市编码",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "130b3a9f-64dc-46e8-9cff-50352c6694f9",
          val: null
        },
        {
          component: "HInput",
          prop: "cityName",
          label: "城市名称",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "8cc7357b-8dea-4f80-ae4c-9c4afb80a5ed",
          val: null
        },
        {
          component: "HInput",
          prop: "countyCode",
          label: "区/县编码",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "3eb0eb33-cf09-484f-b742-8814fb4879a9",
          val: null
        },
        {
          component: "HInput",
          prop: "countyName",
          label: "区/县名称",
          show: false,
          disabled: false,
          view: false,
          col: 12,
          rules: [],
          id: "1a4fccde-d9ab-4fbb-a0cd-095bda11f7f4",
          val: null
        },
        {
          component: "HCascaderArea",
          prop: "areaCode",
          label: "所在地区",
          show: true,
          disabled: false,
          view: true,
          col: 12,
          rules: [],
          id: "42ccfb1c-8447-48a8-a81f-d0635c297328",
          filters: "areaFormat()",
          val: null
        },
        {
          id: "bf73672f-f905-4798-ba25-5b382f21d63a",
          show: true,
          disabled: false,
          view: false,
          component: "HSelect",
          label: "是否二手车",
          prop: "usedCarFlag",
          col: 12,
          rules: []
        }
      ]
    }
  ]
};
