export default {
  code: "setBiStartDateMax",
  name: "设置商业险起期最大值",
  desc: "设置商业险起期最大值",
  func: function () {
    // const form = this.pageForm;
    // const { biEndDate } = form.vehicle;
    // const nowDate = Date.now() + 604800000;
    // if (biEndDate > nowDate) {
    //   this.dateMaxCustom('vehicle.biStartDate', [], biEndDate);
    // } else {
    //   this.dateMaxCustom('vehicle.biStartDate', [], nowDate);
    // }
  }
};
