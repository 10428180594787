export default {
  code: "setOcrIDCard",
  name: "识别的身份证赋值",
  desc: "识别的身份证赋值",
  func: function(idCard) {
    if (!(this.pageForm && this.pageForm.customer)) {
      return false;
    }
    const form = this.pageForm.customer;
    const { name, idNumber, address, birthDate, sex, nation } = idCard;
    form.name = name;
    form.customerName = name;
    form.certificateNo = idNumber;
    form.homeAddress = address;
    form.gender = sex === "男" ? "1" : "2";
    form.birthday = Date.parse(birthDate);
    if (nation) {
      form.ethnicGroup = nation;
    }
  }
};
