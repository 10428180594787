import dayjs from "dayjs";
export default {
  code: "validateTheCarImg",
  name: "是否显示",
  desc: "是否显示",
  func: function () {
    if (!this.pageForm.vehicle.biStartDate || !this.pageForm.vehicle.biEndDate) {
      return true;
    }
    const days = dayjs(this.pageForm.coverage.startDate || this.detailForm.applyTime).diff(dayjs(this.pageForm.vehicle.biStartDate), "day");
    if (days > 10) {
      return true;
    } else {
      return false;
    }
  }
};
