export default {
  code: "feeMatch",
  name: "确认页面匹配费用等信息",
  desc: "确认页面匹配费用等信息",
  func: function(data) {
    if (this.productData.quoteModel && this.productData.quoteModel === "2" && this.pageForm.saleInfo && this.pageForm.saleInfo.saleAmount && data.customerPrice) {
      this.pageForm.saleInfo.saleAmount = data.customerPrice;
    }
  }
};
