import { cloneDeep, isString } from "lodash";
import { Dialog } from "vant";
import router from "@/router";
import scriptLib from "./validatorScript.js";
const requireJs = require.context("./data/order/", false, /\.js$/);
const requireCompanyMap = {};
requireJs.keys().forEach(key => {
  requireCompanyMap[key] = requireJs(key).default;
});
const initPageData = function(layoutData, productCode) {
  // 校验脚本处理
  const ScriptFun = scriptLib.call(this, productCode);
  ScriptFun.prototype.pageForm = this.pageForm;
  ScriptFun.prototype.pageLayout = this.pageForm;
  ScriptFun.prototype.pageLayoutMap = this.pageLayoutMap;
  ScriptFun.prototype.pageOrder = this.pageOrder;
  ScriptFun.prototype.pageCache = this.pageCache;
  // 初始化表单和布局
  // eslint-disable-next-line guard-for-in
  for (const keyCode in layoutData) {
    const formItem = layoutData[keyCode];
    this.pageLayout.push(formItem);
    // this.pageForm[formItem.fmCode] = {};
    this.$set(this.pageForm, formItem.fmCode, {});
    this.pageLayoutMap[formItem.fmCode] = {};
    const scriptObj = new ScriptFun();
    scriptObj.form = this.pageForm[formItem.fmCode];
    const formItems = formItem.formItems || [];
    formItems.forEach(item => {
      // this.pageForm[formItem.fmCode][item.prop] = item.val;
      // 校验特殊处理开始
      if (item.rules) {
        item.rules.forEach(rule => {
          if (rule.pattern && isString(rule.pattern)) {
            // eslint-disable-next-line no-eval
            rule.pattern = eval(rule.pattern);
          } else if (rule.validator && isString(rule.validator)) {
            rule.validator = scriptObj[rule.validator].bind(scriptObj);
          }
        });
      }
      // 校验特殊处理结束
      this.$set(this.pageForm[formItem.fmCode], item.prop, item.val);
      this.pageLayoutMap[formItem.fmCode][item.prop] = item;
    });
  }
};
export default function() {
  const { productCode } = this.$route.query;
  if (!productCode) {
    return false;
  }
  const resData = requireCompanyMap["./" + productCode + ".js"];
  if (!resData) {
    Dialog.alert({
      message: "该产品未配置,请联系管理人员"
    }).then(() => {
      router.go(-1);
    });
    return false;
  }
  initPageData.call(this, cloneDeep(resData.h5TemplateConfig), productCode);
  return resData;
}
