import dayjs from "dayjs";
export default {
  code: "biStartDateChange",
  name: "商业险起保日期修改",
  desc: "商业险起保日期修改",
  func: function() {
    if (!this.pageForm.vehicle.biStartDate) {
      return false;
    }
    const days = dayjs(new Date(this.pageForm.coverage.startDate || Date.now())).diff(dayjs(this.pageForm.vehicle.biStartDate), "day");
    if (days > 10) {
      this.onShow(["images.validateTheCarImg"]);
    } else {
      this.offShow(["images.validateTheCarImg"]);
      if (this.pageForm.images && this.pageForm.images.validateTheCarImg && this.pageForm.images.validateTheCarImg.length > 0) {
        this.pageForm.images.validateTheCarImg = [];
      }
    }
  }
};
